import React from "react";
import { Icon } from "@iconify/react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectItensDoCarrinho } from "@redux/slices/cart";
import { Link } from "gatsby";

export default function HeaderCartButton() {
  const itens = useAppSelector(selectItensDoCarrinho);
  return (
    <Tooltip title="Ir para o carrinho">
      <IconButton
        className="text-white hover:bg-secondary-300"
        color="secondary"
        aria-label="Carrinho de compras"
        component={Link}
        to="/assinaturas/carrinho"
        sx={{ bgcolor: "secondary.main", width: 43, height: 43 }}
      >
        <Badge badgeContent={itens.length} color="primary">
          <Icon icon="mdi-light:cart" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
