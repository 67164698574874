import { mutations } from "./mutations";
import { queries } from "./queries";

export const MINHA_CONTA = {
  queries,
  mutations,
};

export interface ConsultaCnpjResponseDto {
  cnpj_raiz: string;
  razao_social: string;
  capital_social: string;
  responsavel_federativo: any;
  atualizado_em: string;
  porte: Porte;
  natureza_juridica: NaturezaJuridica;
  qualificacao_do_responsavel: any;
  socios: any[];
  simples: Simples;
  estabelecimento: Estabelecimento;
}

export interface Porte {
  id: string;
  descricao: string;
}

export interface NaturezaJuridica {
  id: string;
  descricao: string;
}

export interface Simples {
  simples: string;
  data_opcao_simples: string;
  data_exclusao_simples: any;
  mei: string;
  data_opcao_mei: any;
  data_exclusao_mei: any;
  atualizado_em: string;
}

export interface Estabelecimento {
  cnpj: string;
  atividades_secundarias: AtividadesSecundaria[];
  cnpj_raiz: string;
  cnpj_ordem: string;
  cnpj_digito_verificador: string;
  tipo: string;
  nome_fantasia: string;
  situacao_cadastral: string;
  data_situacao_cadastral: string;
  data_inicio_atividade: string;
  nome_cidade_exterior: any;
  tipo_logradouro: any;
  logradouro: string;
  numero: string;
  complemento: any;
  bairro: string;
  cep: string;
  ddd1: string;
  telefone1: string;
  ddd2: any;
  telefone2: any;
  ddd_fax: any;
  fax: any;
  email: any;
  situacao_especial: any;
  data_situacao_especial: any;
  atualizado_em: string;
  atividade_principal: AtividadePrincipal;
  pais: Pais;
  estado: Estado;
  cidade: Cidade;
  motivo_situacao_cadastral: any;
  inscricoes_estaduais: InscricoesEstaduai[];
  inscricoes_suframa: InscricoesSuframa[];
  regimes_tributarios: any[];
}

export interface AtividadesSecundaria {
  id: string;
  secao: string;
  divisao: string;
  grupo: string;
  classe: string;
  subclasse: string;
  descricao: string;
}

export interface AtividadePrincipal {
  id: string;
  secao: string;
  divisao: string;
  grupo: string;
  classe: string;
  subclasse: string;
  descricao: string;
}

export interface Pais {
  id: string;
  iso2: string;
  iso3: string;
  nome: string;
  comex_id: string;
}

export interface Estado {
  id: number;
  nome: string;
  sigla: string;
  ibge_id: number;
}

export interface Cidade {
  id: number;
  nome: string;
  ibge_id: number;
  siafi_id: string;
}

export interface InscricoesEstaduai {
  inscricao_estadual: string;
  ativo: boolean;
  atualizado_em: string;
  estado: Estado2;
}

export interface Estado2 {
  id: number;
  nome: string;
  sigla: string;
  ibge_id: number;
}

export interface InscricoesSuframa {
  inscricao_suframa: string;
  ativo: boolean;
  atualizado_em: string;
}
