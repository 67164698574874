import { AssinaturaDisponivelModel } from "@features/assinaturas-disponiveis/types";
import { orderBy, round } from "lodash";

export const calcularPercentualDeDesconto = (
  quantidade: number,
  assinaturaDisponivel?: AssinaturaDisponivelModel
) => {
  const tabeDePrecos = orderBy(
    assinaturaDisponivel?.tabelaDePrecos ?? [],
    (x) => x.quantidade
  );

  if (tabeDePrecos.length === 0) return 0;
  let percentual =
    tabeDePrecos.find((item) => item.quantidade >= quantidade)
      ?.percentualDeDesconto ?? 0;

  const subtotal = (assinaturaDisponivel?.valor ?? 0) * quantidade;
  return round(subtotal * (percentual / 100), 2);
};
