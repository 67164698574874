import React from "react";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { UsuarioModel } from "@features/core/types";
import { MINHA_CONTA } from "@features/minha-conta/types";

import { useAuth } from "./AuthContext";

type ProfileContextData = {
  loading: boolean;
  perfil?: UsuarioModel;
  perfilVerificado: boolean;
  reloadPerfil: () => void;
};

const ProfileContext = createContext<ProfileContextData>(
  {} as ProfileContextData
);

function ProfileProvider({ children }: PropsWithChildren) {
  const { user, idToken } = useAuth();
  const perfilQuery = MINHA_CONTA.queries.obterPerfilDoUsuario(
    !!user && !!idToken ? user.uid : undefined
  );

  const value = useMemo<ProfileContextData>(
    () => ({
      loading: perfilQuery.isFetching,
      perfil: perfilQuery.data,
      perfilVerificado: perfilQuery.status !== "loading",
      reloadPerfil: () => perfilQuery.refetch(),
    }),
    [perfilQuery]
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export const useProfile = () => useContext(ProfileContext);

export default ProfileProvider;
