import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function HeaderLogo() {
  return (
    <Link to={`/`}>
      <StaticImage
        src="../../images/logos/logo.svg"
        alt="logo"
        layout="fixed"
        placeholder="none"
        width={107}
        height={120}
      />
    </Link>
  );
}
