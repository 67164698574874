import React, { PropsWithChildren } from "react";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBr from "date-fns/locale/pt-BR";

export default function LocalizationProvider({ children }: PropsWithChildren) {
  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBr}>
      {children}
    </MuiLocalizationProvider>
  );
}
