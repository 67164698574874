import React, { useCallback, useState } from "react";
import { MENU } from "@configs/menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

const _MENU = [
  {
    label: "Página Inicial",
    route: "/",
    itens: [],
  },
  ...MENU,
];

export default function HeaderDrawerButton() {
  const [openned, setOpenned] = useState(false);
  const { pathname } = useLocation();

  const open = useCallback(() => {
    setOpenned(true);
  }, []);
  const close = useCallback(() => {
    setOpenned(false);
  }, []);

  return (
    <>
      <IconButton color="primary" onClick={open}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={openned}
        onOpen={open}
        onClose={close}
        PaperProps={{
          sx: {
            maxWidth: "90%",
          },
        }}
      >
        <List>
          {_MENU.map((item, index) => (
            <div key={item.label}>
              <ListItem disablePadding>
                <ListItemButton component={Link} to={item.route ?? ""}>
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      color:
                        pathname === item.route! ? "secondary" : "text.primary",
                      sx: {
                        fontWeight: "600",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>

              {item.itens && item.itens.length > 0 && (
                <List>
                  {item.itens.map((subitem) => (
                    <ListItem key={subitem.label} disablePadding>
                      <ListItemButton component={Link} to={subitem.route}>
                        <ListItemIcon>
                          <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={subitem.label} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}

              <Divider />
            </div>
          ))}
        </List>
      </SwipeableDrawer>
    </>
  );
}
