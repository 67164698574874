import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createSignalRContext } from "react-signalr/signalr";
import { HttpTransportType, HubConnection } from "@microsoft/signalr";

const SignalrMainContext = createSignalRContext();

type SignalrContextData = {
  connectionId?: string;
  connection?: HubConnection;
};

const SignalrContext = createContext<SignalrContextData>(
  {} as SignalrContextData
);

function SignalrProvider(props: PropsWithChildren) {
  const { children } = props;
  const [connectionId, setConnectionId] = useState<string>();
  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    if (connection?.state === "Connected") {
      connection.invoke("getConnectionId").then((connectionId) => {
        console.log("connectionId", connectionId);
        setConnectionId(connectionId);
      });
    }
  }, [connection]);

  const value = useMemo<SignalrContextData>(
    () => ({
      connectionId,
      connection,
    }),
    [connection, connectionId]
  );

  return (
    <SignalrContext.Provider value={value}>
      <SignalrMainContext.Provider
        url={`${process.env.GATSBY_API_URL}/hubs/payment`}
        transport={HttpTransportType.WebSockets}
        onOpen={setConnection}
        onReconnect={setConnection}
        onClosed={() => setConnection(undefined)}
        connectEnabled
        automaticReconnect
        skipNegotiation
      >
        {children}
      </SignalrMainContext.Provider>
    </SignalrContext.Provider>
  );
}

export const useSignalr = () => useContext(SignalrContext);

export default SignalrProvider;
