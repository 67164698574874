import { ptBR as corePtBR } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/x-data-grid";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#203460",
      },
      secondary: {
        main: "#2aa6ee",
        contrastText: "#ffffff",
      },
    },
    typography: {
      fontFamily: [
        "Open Sans",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  },
  ptBR,
  corePtBR
);
export default theme;
