import React from "react";
import "firebase/auth";
import "./src/styles/global.css";

import AuthContext from "./src/contexts/AuthContext";

export const wrapRootElement = ({ element }) => (
  <>
    <AuthContext>{element}</AuthContext>

    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3766006,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
      }}
    />

    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-TZ2QQHSH"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  </>
);
