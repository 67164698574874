import { toast } from "react-hot-toast";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => {
      handleError(error);
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.ignoreErrors) return;
      handleError(error);
    },
  }),
});

const handleError = (error: unknown) => {
  const err = error as AxiosError<any>;
  const code = err?.response?.status ?? 500;
  let messages: string[] = [];

  switch (code) {
    case 401:
      messages.push("Acesso negado");
      break;
    case 403:
      messages.push("Acesso não autorizado");
      break;
    case 400:
      if (err?.response?.data?.errors) {
        messages = err.response.data.errors;
      } else if (err?.response?.data) {
        messages = err.response.data.map((x) => x.message);
      }

      break;
    default:
      messages.push(
        "Não foi possível processar sua solicitação no momento, tente novamente mais tarde"
      );
      break;
  }

  console.log(messages);

  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];
    toast.error(msg, { duration: 7000 });
  }
};
