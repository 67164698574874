import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";

import HeaderActions from "./HeaderActions";
import HeaderLogo from "./HeaderLogo";

export default function HeaderTop() {
  return (
    <div className="flex space-x-14">
      <HeaderLogo />

      <div className="flex flex-1 flex-col space-y-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-1 text-secondary-400">
            <PhoneIcon />
            <span className="text-xs font-bold">
              (61) 2193-3019
            </span>
          </div>

          <div className="flex items-center space-x-10">
            <span className="text-xs font-semibold text-[#91afc2]">
              <a
                href="https://liturgiadiaria.cnbb.org.br/"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "inherit",
                  textDecorationSkipInk: "inherit",
                  textDecorationColor: "inherit",
                  textEmphasisColor: "inherit",
                  color: "inherit",
                  padding: "inherit",
                }}
              >
                LITURGIA DIÁRIA
              </a>
            </span>
            <span className="text-xs font-semibold text-[#91afc2]">
              <a
                href="https://www.edicoescnbb.com.br"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "inherit",
                  textDecorationSkipInk: "inherit",
                  textDecorationColor: "inherit",
                  textEmphasisColor: "inherit",
                  color: "inherit",
                  padding: "inherit",
                }}
              >
                LOJA ONLINE
              </a>
            </span>

            <span className="text-xs font-semibold text-[#91afc2]">
              <a
                href="https://www.edicoescnbb.com.br/noticias"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "inherit",
                  textDecorationSkipInk: "inherit",
                  textDecorationColor: "inherit",
                  textEmphasisColor: "inherit",
                  color: "inherit",
                  padding: "inherit",
                }}
              >
                BLOG
              </a>
            </span>
            
            <span className="text-xs font-semibold text-[#91afc2]">
              <a
                href="https://api.whatsapp.com/send?phone=556121933019&text=Ol%C3%A1,%20Edi%C3%A7%C3%B5es%20CNBB.%20Estava%20navegando%20no%20portal%20de%20assinaturas%20e%20tive%20uma%20d%C3%BAvida.%20Por%20favor"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "inherit",
                  textDecorationSkipInk: "inherit",
                  textDecorationColor: "inherit",
                  textEmphasisColor: "inherit",
                  color: "inherit",
                  padding: "inherit",
                }}
              >
                {" "}
                CENTRAL DE ATENDIMENTO{" "}
              </a>
            </span>
          </div>
        </div>

        <div className="flex flex-1 items-center">
          <div className="flex flex-1 justify-between space-x-16">
            {/* <HeaderSearch /> */}
            <HeaderActions />
          </div>
        </div>
      </div>
    </div>
  );
}
