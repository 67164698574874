import React from "react";
import { useLocation } from "@reach/router";

import HeaderMenu from "./HeaderMenu";
import HeaderMobile from "./HeaderMobile";
import HeaderTop from "./HeaderTop";

const Header: React.FC = () => {
  const { pathname } = useLocation();

  const mobileBgColor = pathname === "/" ? "bg-white" : "bg-transparent";

  return (
    <div
      className={`z-10 ${mobileBgColor} py-3 shadow-none lg:bg-white lg:shadow`}
    >
      <div className="app-container hidden flex-col space-y-4 lg:flex">
        <HeaderTop />
        <HeaderMenu />
      </div>
      <div className="app-container flex flex-col lg:hidden">
        <HeaderMobile />
      </div>
    </div>
  );
};

export default Header;
