import { api } from "@lib/axios";
import { useMutation } from "@tanstack/react-query";

const atualizarPerfil = () =>
  useMutation({
    mutationFn: (input: any) => {
      return api.put(`MinhaConta`, input);
    },
  });

const adicionarEnderecoDeEntrega = () =>
  useMutation({
    mutationFn: (input: any) => {
      return api.post(`MinhaConta/enderecos`, input);
    },
  });

const removerEnderecoDeEntrega = () =>
  useMutation({
    mutationFn: (enderecoId: string) => {
      return api.delete(`MinhaConta/enderecos/${enderecoId}`);
    },
  });

export const mutations = {
  atualizarPerfil,
  adicionarEnderecoDeEntrega,
  removerEnderecoDeEntrega,
};
