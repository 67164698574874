import {
  CidadeModel,
  EstadoModel,
  PedidoAssinaturaDisponivelModel,
  PedidoModel,
  RemessaDetalheModel,
  RemessaModel,
  UsuarioEnderecoModel,
  UsuarioModel,
} from "@features/core/types";
import { api } from "@lib/axios";
import { useQuery } from "@tanstack/react-query";
import { validateCNPJ } from "validations-br";

import { ConsultaCnpjResponseDto } from "./types";

const obterPerfilDoUsuario = (uid?: string) =>
  useQuery(
    ["obterPerfilDoUsuario", uid],
    () => api.get<UsuarioModel>(`/MinhaConta`).then((x) => x.data),
    { enabled: !!uid }
  );

const obterEnderecosDoUsuario = () =>
  useQuery(["obterEnderecosDoUsuario"], () =>
    api.get<UsuarioEnderecoModel[]>(`/MinhaConta/enderecos`).then((x) => x.data)
  );

const obterPedidosDoUsuario = () =>
  useQuery(["obterPedidosDoUsuario"], () =>
    api.get<PedidoModel[]>(`/Pedidos`).then((x) => x.data)
  );

const obterPedidoDoUsuarioPorId = (id: string) =>
  useQuery(
    ["obterPedidoDoUsuarioPorId", id],
    () => api.get<PedidoModel>(`/Pedidos/${id}`).then((x) => x.data),
    { enabled: !!id }
  );

const obterAssinaturasDoUsuario = () =>
  useQuery(["obterAssinaturasDoUsuario"], () =>
    api
      .get<PedidoAssinaturaDisponivelModel[]>(`/Assinaturas`)
      .then((x) => x.data)
  );

const obterEstados = () =>
  useQuery(["obterEstados"], () =>
    api.get<EstadoModel[]>(`/Enderecos/estados`).then((x) => x.data)
  );

const obterRemessasPorContrato = (numeroDoContrato?: number) =>
  useQuery(
    ["obterRemessasPorContrato", numeroDoContrato],
    () =>
      api
        .get<RemessaModel[]>(`/Contratos/${numeroDoContrato}/remessas`)
        .then((x) => x.data),
    { enabled: !!numeroDoContrato }
  );

const obterDetalheDaRemessa = (
  numeroDoContrato?: number,
  numeroDaRemessa?: number
) =>
  useQuery(
    ["obterDetalheDaRemessa", numeroDoContrato, numeroDaRemessa],
    () =>
      api
        .get<RemessaDetalheModel>(
          `/Contratos/${numeroDoContrato}/remessas/${numeroDaRemessa}`
        )
        .then((x) => x.data),
    { enabled: !!numeroDoContrato && !!numeroDaRemessa }
  );

const obterCidadesPorEstado = (estadoId: string) =>
  useQuery(
    ["obterCidadesPorEstado", estadoId],
    () =>
      api
        .get<CidadeModel[]>(`/Enderecos/estados/${estadoId}/cidades`)
        .then((x) => x.data),
    { enabled: !!estadoId }
  );

const buscarCnpj = (cnpj: string) =>
  useQuery(
    ["buscarCnpj", cnpj],
    () =>
      api
        .get<ConsultaCnpjResponseDto>(`/PessoasJuridicas/${cnpj}`)
        .then((x) => x.data),
    { enabled: !!cnpj && cnpj.length === 14 && validateCNPJ(cnpj) }
  );

export const queries = {
  obterPerfilDoUsuario,
  obterPedidosDoUsuario,
  obterPedidoDoUsuarioPorId,
  obterEnderecosDoUsuario,
  obterAssinaturasDoUsuario,
  obterEstados,
  obterCidadesPorEstado,
  obterRemessasPorContrato,
  obterDetalheDaRemessa,
  buscarCnpj,
};
