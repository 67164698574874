export const MENU = [
  {
    label: "Liturgia Igreja em Oração",
    route: "/assinaturas/liturgia-igreja-em-oração/",
    itens: [
      {
        label: "Liturgia Igreja em Oração",
        route: "/assinaturas/liturgia-igreja-em-oração/",
      },
      {
        label: "Liturgia Igreja em Oração - Letra Grande",
        route: "/assinaturas/liturgia-igreja-em-oração-letra-grande/",
      },
    ],
  },
  {
    label: "Folhetos",
    itens: [
      {
        label: "Semanário Litúrgico-catequético",
        route: "/assinaturas/semanário-litúrgico-catequético/",
      },
      {
        label: "Celebração Dominical da Palavra",
        route: "/assinaturas/celebração-dominical-da-palavra/",
      },
    ],
  },
  {
    label: "Roteiros Homiléticos",
    route: "/assinaturas/roteiros-homiléticos/",
  },
  {
    label: "Folhetos e Partituras",
    route: "https://drive.google.com/drive/folders/1BrCJskeBmrjRb_XgHsShBwnvFYGap8Gs?usp=sharing",
  },
];
