import React from "react";
import { Button } from "@mui/material";
import { Link } from "gatsby";

export default function HeaderAssinaturasButton() {
  return (
    <Button
      color="primary"
      variant="text"
      className="rounded-full font-bold"
      component={Link}
      to="/minha-conta/assinaturas"
      sx={{ height: 43, px: "31px" }}
    >
      Minhas assinaturas
    </Button>
  );
}
