import React from "react";

const WhatsappFab: React.FC = () => {
  return (
    <a
      href="https://wa.me/556121933019?text=Ol%C3%A1.+Estava+navegando+no+site+de+assinaturas+e+preciso+falar+com+um+consultor.+Por+favor"
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: "none",
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 10,
      }}
    >
      <img
        className="max-w-[300px] sm:max-w-sm lg:max-w-sm xl:max-w-none"
        src="/images/whatsapp.png"
        alt="whatsapp"
        width={60}
      />
    </a>
  );
};

export default WhatsappFab;
