import React from "react";
import { Box } from "@mui/material";

import HeaderCartButton from "./HeaderCartButton";
import HeaderDrawerButton from "./HeaderDrawerButton";
import HeaderLoginButton from "./HeaderLoginButton";
import HeaderLogo from "./HeaderLogo";
import HeaderUserAvatar from "./HeaderUserAvatar";

export default function HeaderMobile() {
  return (
    <div className="flex items-center space-x-5">
      <HeaderLogo />
      <Box sx={{ flex: 1 }} />
      <HeaderLoginButton />
      <HeaderUserAvatar />
      <HeaderCartButton />
      <HeaderDrawerButton />
    </div>
  );
}
