import React from "react";
import { Icon } from "@iconify/react";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Footer: React.FC = () => {
  const className =
    "text-[#054976] no-underline hover:text-secondary-400 hover:cursor-pointer";
  
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-[#E2E5EA] text-[#054976]" style={{ fontSize: 15 }}>
      <div className="app-container flex flex-col items-center space-y-5 px-8 py-10 lg:px-0">
        <div className="flex w-full flex-col items-center space-y-6">
          <h3
            style={{ fontSize: 20, lineHeight: 1 }}
            className="font-bold text-[#142341]"
          >
            Formas de Pagamento
          </h3>

          <StaticImage
            src="../../images/outros/formas_pagamento.png"
            alt="logo"
            layout="constrained"
            placeholder="none"
            height={29}
          />

          <Divider style={{ width: "95%" }} />
        </div>

        <div className="grid grid-cols-2 gap-10 text-left sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div className="flex flex-col space-y-1">
            <h3 style={{ fontSize: 20 }} className=" font-bold text-[#142341]">
              Institucional
            </h3>

            <a
              className={className}
              href="https://www.edicoescnbb.com.br/lojas-fisicas"
              target="_blank"
              rel="noreferrer"
            >
              Lojas Fisicas{" "}
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/empresa"
              target="_blank"
              rel="noreferrer"
            >
              Quem Somos{" "}
            </a>
            <a
              className={className}
              href="https://www.cnbb.org.br/"
              target="_blank"
              rel="noreferrer"
            >
              cnbb.org{" "}
            </a>
            <a
              className={className}
              href="https://www.cursosedicoescnbb.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              Cursos CNBB{" "}
            </a>
            <a
              className={className}
              href="https://liturgiadiaria.edicoescnbb.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              Liturgia Diária
            </a>
          </div>
          <div className="flex flex-col space-y-1">
            <h3 style={{ fontSize: 20 }} className=" font-bold text-[#142341]">
              Políticas
            </h3>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/trocas-e-devolucoes"
              target="_blank"
              rel="noreferrer"
            >
              Trocas e Devoluções
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/pagamento"
              target="_blank"
              rel="noreferrer"
            >
              Pagamento
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/envio"
              target="_blank"
              rel="noreferrer"
            >
              Entrega
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/termos-de-uso"
              target="_blank"
              rel="noreferrer"
            >
              Termos de Uso
            </a>
            <a
              className={className}
              href="https://www.cnbb.org.br/termo-de-privacidade-cnbb/"
              target="_blank"
              rel="noreferrer"
            >
              Política de Privacidade
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/regulamentos"
              target="_blank"
              rel="noreferrer"
            >
              Regulamentos
            </a>
          </div>
          <div className="flex flex-col space-y-1">
            <h3 style={{ fontSize: 20 }} className=" font-bold text-[#142341]">
              Assinaturas
            </h3>
            <Link
              className={className}
              to="/assinaturas/liturgia-igreja-em-oração/"
            >
              Liturgia Igreja em Oração
            </Link>
            <Link
              className={className}
              to="/assinaturas/semanário-litúrgico-catequético/"
            >
              Semanário Litúrgico-catequético
            </Link>
            <Link
              className={className}
              to="/assinaturas/celebração-dominical-da-palavra/"
            >
              Celebração Dominical da Palavra
            </Link>
            <Link className={className} to="/assinaturas/roteiros-homiléticos/">
              Roteiros Homiléticos
            </Link>
            <Link className={className} to="https://drive.google.com/drive/folders/1BrCJskeBmrjRb_XgHsShBwnvFYGap8Gs?usp=sharing">
              Folhetos e Partituras
            </Link>
            <Link className={className} to="/">
              Como funciona?
            </Link>
            <Link className={className} to="/minha-conta/resumo/">
              Portal do Assinante
            </Link>
            <Link className={className} to="/">
              Perguntas Frequentes
            </Link>
            <span
              className={className}
              onClick={() =>
                document
                  .getElementById("depoimentos")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Depoimentos
            </span>
          </div>
          <div className="flex flex-col space-y-1">
            <h3 style={{ fontSize: 20 }} className=" font-bold text-[#142341]">
              Loja Oficial
            </h3>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/lancamentos"
              target="_blank"
              rel="noreferrer"
            >
              Lançamentos
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/biblias"
              target="_blank"
              rel="noreferrer"
            >
              Bíblias - Tradução Oficial
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/lancamentos"
              target="_blank"
              rel="noreferrer"
            >
              Campanha da Fraternidade
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/papas"
              target="_blank"
              rel="noreferrer"
            >
              Papas
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/colecoes"
              target="_blank"
              rel="noreferrer"
            >
              Coleções
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/catequese-e-espiritualidade"
              target="_blank"
              rel="noreferrer"
            >
              Catequese
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/santa-se-e-dicasterios"
              target="_blank"
              rel="noreferrer"
            >
              Santa Sé
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/devocional-e-biografias/devocional"
              target="_blank"
              rel="noreferrer"
            >
              Devocional
            </a>
            <a
              className={className}
              href="https://www.edicoescnbb.com.br/loja/central_anteriores.php?loja=1076813"
              target="_blank"
              rel="noreferrer"
            >
              Meus Pedidos
            </a>
          </div>
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-1">
              <h3
                style={{ fontSize: 20 }}
                className=" font-bold text-[#142341]"
              >
                Canais de Atendimento
              </h3>
              <a
                href="https://wa.me/556121933019?text=Ol%C3%A1.+Estava+navegando+no+site+de+assinaturas+e+preciso+falar+com+um+consultor.+Por+favor"
                style={{
                  color: "#054976",
                  fontSize: 22,
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noreferrer"
              >
                (61) 2193-3019
              </a>
              <span>De segunda à sexta das 8h às 18h</span>
            </div>
            <div className="flex flex-col space-y-2">
              <h3
                style={{ fontSize: 20 }}
                className=" font-bold text-[#142341]"
              >
                Redes sociais
              </h3>

              <div className="-ml-3 flex">
                <Tooltip title="Facebook">
                  <IconButton
                    className="text-[#054976]"
                    size="small"
                    sx={{ width: 35, height: 35, fontSize: 22 }}
                    component="a"
                    href="https://www.facebook.com/edicoes.cnbb"
                    target="_blank"
                  >
                    <Icon icon="fa:facebook" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Instagram">
                  <IconButton
                    className="text-[#054976]"
                    size="small"
                    sx={{ width: 35, height: 35, fontSize: 22 }}
                    component="a"
                    href="https://www.instagram.com/edicoescnbb"
                    target="_blank"
                  >
                    <Icon icon="fa:instagram" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Twitter">
                  <IconButton
                    className="text-[#054976]"
                    size="small"
                    sx={{ width: 35, height: 35, fontSize: 22 }}
                    component="a"
                    href="https://www.twitter.com/EDICOESCNBB"
                    target="_blank"
                  >
                    <Icon icon="fa:twitter" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Youtube">
                  <IconButton
                    className="text-[#054976]"
                    size="small"
                    sx={{ width: 35, height: 35, fontSize: 22 }}
                    component="a"
                    href="https://www.youtube.com/channel/UCUnulF06X8AyrEgE04OkIOA"
                    target="_blank"
                  >
                    <Icon icon="ri:youtube-line" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <p className="text-center" style={{ fontSize: 14 }}>
          CONFERENCIA NACIONAL DOS BISPOS DO BRASIL - EDIÇÕES CNBB
          33.685.686/0019-80 0746813400220
          <br />
          SAAN Quadra 3 Lote 590/600 Zona Industrial Brasília DF 70.632-350
        </p>

        <p className="text-center" style={{ fontSize: 14 }}>
          Eventuais promoções, descontos e prazos de pagamento expostos aqui são
          válidos apenas para compras via internet. As fotos, os textos e o
          <br />
          layout aqui veiculados são de propriedade da Loja. É proibida a
          utilização total ou parcial sem nossa autorização.
        </p>

        <p className="text-center" style={{ fontSize: 14 }}>
          {currentYear} © Todos Direitos Reservados
        </p>

        <div className="flex items-center space-x-1">
          <span className="text-xs">Desenvolvido por</span>

          <a
            className="flex items-center"
            href="https://ipolitus.com/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../images/logos/logo-ipolitus.webp"
              alt="Ipolitus"
              width={93}
              height={20}
              layout="constrained"
              placeholder="none"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
