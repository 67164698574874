import React, { useCallback } from "react";
import { PropsWithChildren, useEffect } from "react";
import { ItemDoCarrinhoModel } from "@features/carrinho-compras/types";
import { api } from "@lib/axios";
import { useAppDispatch } from "@redux/hooks";
import { setCartItems } from "@redux/slices/cart";

import { useAuth } from "./AuthContext";

type CarrinhoResponse = {
  itens: ItemDoCarrinhoModel[];
};

export default function CartContext({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const { idToken } = useAuth();

  const checkCart = useCallback(() => {
    if (idToken) {
      api.get<CarrinhoResponse>("/Carrinhos/em-aberto").then(({ data }) => {
        if (data && data.itens && data.itens.length > 0) {
          dispatch(setCartItems(data.itens));
        }
      });
    }
  }, [dispatch, idToken]);

  useEffect(() => {
    checkCart();
  }, [checkCart]);

  return <>{children}</>;
}
