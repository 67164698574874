import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import { api } from "@lib/axios";
import { auth } from "@lib/firebase";
import { User } from "firebase/auth";

type AuthContextData = {
  loading: boolean;
  user: User | null | undefined;
  error: Error | undefined;
  idToken?: string;
  providerId?: string;
  providerLabel?: string;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: PropsWithChildren) {
  const [idToken, setIdToken] = useState<string>();
  const [user, loading, error] = useIdToken(auth, {
    onUserChanged: async (user) => {
      const idToken = await user?.getIdToken();
      if (idToken) api.defaults.headers.authorization = `Bearer ${idToken}`;
      setIdToken(idToken);
    },
  });

  const providerId = useMemo(() => user?.providerData[0].providerId, [user]);
  const providerLabel = useMemo(() => {
    switch (providerId) {
      case "google.com":
        return "Google";
      case "facebook.com":
        return "Facebook";
      default:
        return "E-mail e senha";
    }
  }, [providerId]);

  const value = useMemo<AuthContextData>(
    () => ({
      loading: loading,
      user,
      error,
      providerId,
      providerLabel,
      idToken,
    }),
    [loading, user, error, providerId, providerLabel, idToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
