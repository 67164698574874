import React from "react";
import { MENU } from "@configs/menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "gatsby";

const HeaderMenu: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setActiveIndex(index);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setActiveIndex(-1);
    setAnchorEl(null);
  };

  return (
    <div className="flex justify-center space-x-14 rounded-lg bg-[#fbfbfb] px-3 py-3">
      {MENU.map((item, index) => {
        if (item.itens) {
          return (
            <div key={index}>
              <Button
                className="flex items-center space-x-1 text-sm font-semibold text-[#142341] no-underline hover:bg-transparent hover:text-secondary-400"
                endIcon={<ExpandMoreIcon />}
                onClick={(e) => handleClick(e, index)}
                style={{
                  textTransform: "inherit",
                  transitionDuration: "inherit",
                  opacity: "inherit",
                }}
              >
                {item.label}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open && activeIndex === index}
                onClose={handleClose}
              >
                {item.itens.map((menu) => {
                  return (
                    <MenuItem key={menu.label} onClick={handleClose}>
                      <Link
                        to={menu.route}
                        className="flex items-center space-x-1 text-sm font-semibold text-[#142341] no-underline hover:text-secondary-400"
                        style={{
                          textDecoration: "inherit",
                          textDecorationSkipInk: "inherit",
                          textDecorationColor: "inherit",
                          textEmphasisColor: "inherit",
                          color: "inherit",
                          padding: "inherit",
                        }}
                      >
                        {menu.label}
                      </Link>
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          );
        }

        return (
          <Link
            key={index}
            to={item.route}
            className="flex items-center space-x-1 text-sm font-semibold text-[#142341] no-underline hover:text-secondary-400"
          >
            <span>{item.label}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default HeaderMenu;
