import { calcularPercentualDeDesconto } from "@features/assinaturas-disponiveis/utils/calcularPercentualDeDesconto";
import { ItemDoCarrinhoModel } from "@features/carrinho-compras/types";
import { api } from "@lib/axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";

type CartAction<T> = {
  isSignedIn: boolean;
  payload: T;
};

const SLICE_NAME = "cart";

type State = {
  items: ItemDoCarrinhoModel[];
};

const initialState: State = {
  items: [],
};

// async thunks
export const adicionarItemAoCarrinho = createAsyncThunk(
  "cart/adicionarItemAoCarrinho",
  async (action: CartAction<ItemDoCarrinhoModel>, { getState }) => {
    const state = getState() as RootState;

    let items = state.cart.items.map((x) => ({
      assinaturaDisponivel: x.assinaturaDisponivel,
      primeiroExemplarId: x.primeiroExemplarId,
      quantidade: x.quantidade,
    }));

    const index = items.findIndex(
      (x) =>
        x.assinaturaDisponivel.id === action.payload.assinaturaDisponivel.id
    );

    if (index >= 0) {
      items[index].quantidade += action.payload.quantidade;
    } else {
      items.push(action.payload);
    }

    if (action.isSignedIn) {
      api.put("/Carrinhos/em-aberto", {
        itens: items.map((x) => ({
          assinaturaDisponivelId: x.assinaturaDisponivel.id,
          primeiroExemplarId: x.primeiroExemplarId,
          quantidade: x.quantidade,
        })),
      });
    }

    return action.payload;
  }
);
export const removerItemDoCarrinho = createAsyncThunk(
  "cart/removerItemDoCarrinho",
  async (action: CartAction<number>, { getState }) => {
    const state = getState() as RootState;

    if (action.isSignedIn) {
      api.put("/Carrinhos/em-aberto", {
        itens: state.cart.items
          .filter((_, i) => i !== action.payload)
          .map((x) => ({
            assinaturaDisponivelId: x.assinaturaDisponivel.id,
            primeiroExemplarId: x.primeiroExemplarId,
            quantidade: x.quantidade,
          })),
      });
    }

    return action.payload;
  }
);
export const definirQuantidadeDoItemDoCarrinho = createAsyncThunk(
  "cart/definirQuantidadeDoItemDoCarrinho",
  async (
    action: CartAction<{ index: number; quantidade: number }>,
    { getState }
  ) => {
    const state = getState() as RootState;

    if (action.isSignedIn) {
      api.put("/Carrinhos/em-aberto", {
        itens: state.cart.items.map((x) => ({
          assinaturaDisponivelId: x.assinaturaDisponivel.id,
          primeiroExemplarId: x.primeiroExemplarId,
          quantidade: action.payload.quantidade,
        })),
      });
    }

    return action.payload;
  }
);
export const limparCarrinho = createAsyncThunk(
  "cart/limparCarrinho",
  async (action: CartAction<any>) => {
    if (action.isSignedIn) {
      api.put("/Carrinhos/em-aberto", {
        itens: [],
      });
    }
  }
);

// slice
export const cart = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCartItems: (state, action: PayloadAction<ItemDoCarrinhoModel[]>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adicionarItemAoCarrinho.fulfilled, (state, action) => {
      const index = state.items.findIndex(
        (x) =>
          x.assinaturaDisponivel.id === action.payload.assinaturaDisponivel.id
      );

      if (index >= 0) {
        state.items[index].quantidade += action.payload.quantidade;
      } else {
        state.items.push(action.payload);
      }
    });

    builder.addCase(removerItemDoCarrinho.fulfilled, (state, action) => {
      state.items.splice(action.payload, 1);
    });

    builder.addCase(
      definirQuantidadeDoItemDoCarrinho.fulfilled,
      (state, action) => {
        state.items[action.payload.index].quantidade =
          action.payload.quantidade;
      }
    );

    builder.addCase(limparCarrinho.fulfilled, (state) => {
      state.items = [];
    });
  },
});

// actions
export const { setCartItems } = cart.actions;

// selectors
export const selectCarrinho = (state: RootState) => state.cart;

export const selectItensDoCarrinho = (state: RootState) => state.cart.items;

export const selectSumarioDoCarrinho = (state: RootState) => {
  let total = 0;

  state.cart.items.forEach((item) => {
    const desconto = calcularPercentualDeDesconto(
      item.quantidade,
      item.assinaturaDisponivel
    );
    const subtotal = item.assinaturaDisponivel.valor * item.quantidade;
    total += subtotal - desconto;
  });

  return {
    total,
    quantidadeDeProdutos: state.cart.items.length,
  };
};

export const selectSumarioDoCarrinhoPorItem = (state: RootState) => {
  let sumario: {
    subtotal: number;
    desconto: number;
    total: number;
  }[] = [];

  state.cart.items.forEach((item) => {
    const desconto = calcularPercentualDeDesconto(
      item.quantidade,
      item.assinaturaDisponivel
    );
    const subtotal = item.assinaturaDisponivel.valor * item.quantidade;
    const total = subtotal - desconto;

    sumario.push({
      subtotal,
      desconto,
      total,
    });
  });

  return sumario;
};

// reducer
export default cart.reducer;
