import React, { PropsWithChildren } from "react";
import { Provider as ReduxProvider } from "react-redux";
import Footer from "@components/Footer";
import Header from "@components/Header";
import WhatsappFab from "@components/WhatsappFab";
import CartContext from "@contexts/CartContext";
import LocalizationProvider from "@contexts/LocalizationProvider";
import ProfileProvider from "@contexts/ProfileContext";
import SignalrProvider from "@contexts/SignalrProvider";
import ThemeContext from "@contexts/ThemeContext";
import { queryClient } from "@lib/react-query";
import { persistor, store } from "@redux/store";
import { QueryClientProvider } from "@tanstack/react-query";
import { PageProps } from "gatsby";
import { PersistGate } from "redux-persist/integration/react";

type Props = PageProps &
  PropsWithChildren & {
    pageContext?: any;
  };

export default function RootLayout({ children, pageContext }: Props) {
  const exibirLayoutPadrao = pageContext?.layout !== "blank";

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CartContext>
            <LocalizationProvider>
              <ProfileProvider>
                <SignalrProvider>
                  <ThemeContext>
                    <div className="flex flex-col">
                      {exibirLayoutPadrao && <Header />}
                      <main className="flex flex-col">{children}</main>
                      {exibirLayoutPadrao && <Footer />}
                    </div>

                    <WhatsappFab />
                  </ThemeContext>
                </SignalrProvider>
              </ProfileProvider>
            </LocalizationProvider>
          </CartContext>
        </PersistGate>
      </ReduxProvider>
    </QueryClientProvider>
  );
}
