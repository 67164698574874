exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-assinaturas-[id]-index-tsx": () => import("./../../../src/pages/assinaturas/[id]/index.tsx" /* webpackChunkName: "component---src-pages-assinaturas-[id]-index-tsx" */),
  "component---src-pages-assinaturas-carrinho-tsx": () => import("./../../../src/pages/assinaturas/carrinho/[...].tsx" /* webpackChunkName: "component---src-pages-assinaturas-carrinho-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth/[...].tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-folhetos-e-partituras-index-tsx": () => import("./../../../src/pages/folhetos-e-partituras/index.tsx" /* webpackChunkName: "component---src-pages-folhetos-e-partituras-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-minha-conta-tsx": () => import("./../../../src/pages/minha-conta/[...].tsx" /* webpackChunkName: "component---src-pages-minha-conta-tsx" */),
  "component---src-pages-pagamento-externo-[id]-erro-tsx": () => import("./../../../src/pages/pagamento-externo/[id]/erro.tsx" /* webpackChunkName: "component---src-pages-pagamento-externo-[id]-erro-tsx" */),
  "component---src-pages-pagamento-externo-[id]-index-tsx": () => import("./../../../src/pages/pagamento-externo/[id]/index.tsx" /* webpackChunkName: "component---src-pages-pagamento-externo-[id]-index-tsx" */),
  "component---src-pages-pagamento-externo-[id]-pagamento-aprovado-tsx": () => import("./../../../src/pages/pagamento-externo/[id]/pagamento-aprovado.tsx" /* webpackChunkName: "component---src-pages-pagamento-externo-[id]-pagamento-aprovado-tsx" */),
  "component---src-pages-pagamento-externo-[id]-pix-tsx": () => import("./../../../src/pages/pagamento-externo/[id]/pix.tsx" /* webpackChunkName: "component---src-pages-pagamento-externo-[id]-pix-tsx" */),
  "component---src-pages-pagamento-tsx": () => import("./../../../src/pages/pagamento/[...].tsx" /* webpackChunkName: "component---src-pages-pagamento-tsx" */)
}

