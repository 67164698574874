import React from "react";
import { useAuth } from "@contexts/AuthContext";
import { CircularProgress } from "@mui/material";

import HeaderAssinaturasButton from "./HeaderAssinaturasButton";
import HeaderCartButton from "./HeaderCartButton";
import HeaderLoginButton from "./HeaderLoginButton";
import HeaderUserAvatar from "./HeaderUserAvatar";

export default function HeaderActions() {
  const { user, loading } = useAuth();
  return (
    <div className="flex shrink-0 flex-grow items-center justify-end space-x-3">
      {loading && <CircularProgress />}
      {!loading && user && <HeaderAssinaturasButton />}
      {!loading && user && <HeaderUserAvatar />}
      {!loading && !user && <HeaderLoginButton />}
      <HeaderCartButton />
    </div>
  );
}
