import React from "react";
import { useAuth } from "@contexts/AuthContext";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Button } from "@mui/material";
import { Link } from "gatsby";

export default function HeaderLoginButton() {
  const { user } = useAuth();

  if (user) return null;

  return (
    <Button
      color="primary"
      variant="outlined"
      aria-label="Login"
      className="rounded-full font-bold"
      startIcon={<PermIdentityOutlinedIcon />}
      component={Link}
      to="/auth/login"
      sx={{ height: 43, px: "31px" }}
    >
      Login
    </Button>
  );
}
